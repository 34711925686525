/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
// import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.css';

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div>
                <main className="siteContent">{children}</main>
                <footer className="footer">
                    <div className="wrapper">
                        <p>© {new Date().getFullYear()} Lisa Low</p>
                        <p>
                            Designed and built by{' '}
                            <a href="https://samlow-chappell.com">
                                Sam Low-Chappell
                            </a>
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
};

// Layout.propTypes = {
//     children: PropTypes.node.isRequired,
// };

export default Layout;
